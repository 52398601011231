import React from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useState, handleClick } from "react";
// import logo from "/src/logo192.png";

const Login = () => {
  let [loginToken, setLoginToken] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  let [project_ids, setProjectids] = useState([]);
  let [device_ids, setDeviceids] = useState([]);
  let [imeilist, setImeilist] = useState([]);
  let [location_list, setLocationList] = useState([]);
  let [name_list, setNameList] = useState([]);
  let [headers, setHeaders] = useState({});
  let [token, setToken] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    //navigate

    // if (username === allowedUser && password === allowedPassword) {
    axios
      .post("https://srt.smart-iot.in/api/super-admin/login", {
        // username: "Shubham",
        // password: "Shubham@123",
        // remember: true,
        // domain: "vidani",
        username: username,
        password: password,
        remember: true,
        domain: "srt",
      })
      .then(function (response) {
        // console.log(response.data.message);
        // console.log(response.data.data);
        token = response.data.data;
        loginToken = response.data.data;
        loginToken = localStorage.setItem(
          "loginToken",
          JSON.stringify(loginToken)
        );
        // console.log(localStorage.getItem("loginToken"));
        setLoginToken(loginToken);
        // console.log(token);
        setToken(token);
        headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        // console.log(headers);
        setHeaders(headers);
        // console.log(headers);
        //   navigate({
        //     pathname: "/Front.js",
        //     state: { loginToken: loginToken },
        //   });
        axios
          .get(
            "https://srt.smart-iot.in/api/super-admin/project/getallprojects/5",
            { headers }
          )
          .then(function (response) {
            // console.log(response.data);
            // console.log(response.data.data.projects[0].id);
            let x = response.data.data.projects;
            // console.log(x);
            let loop = x.length;
            // console.log(loop);
            project_ids = [];
            for (let i = 0; i < loop; i++) {
              project_ids[i] = x[i].id;
            }
            // console.log(project_ids);
            setProjectids(project_ids);

            axios
              .get(
                `https://srt.smart-iot.in/api/super-admin/project/getallprojectdevices/5/${project_ids[0]}`,
                { headers }
              )
              .then(function (response) {
                // console.log(response.data);
                // console.log(response.data.data.devices[0].imei);
                // console.log(response.data.data.devices[0].location);
                let y = response.data.data.devices;
                // console.log(y.length);
                for (let i = 0; i < y.length; i++) {
                  imeilist[i] = y[i].imei;
                  device_ids[i] = y[i].id;
                  location_list[i] = y[i].location;
                  name_list[i] = y[i].name;
                }
                // console.log(imeilist);
                // console.log(device_ids);
                setLocationList(location_list);
                setNameList(name_list);
                setDeviceids(device_ids);
                setImeilist(imeilist);
                if (imeilist) {
                  localStorage.setItem("imeilist", JSON.stringify(imeilist));
                }
                if (device_ids) {
                  localStorage.setItem(
                    "device_ids",
                    JSON.stringify(device_ids)
                  );
                }
                if (name_list) {
                  localStorage.setItem("name_list", JSON.stringify(name_list));
                }

                if (location_list) {
                  localStorage.setItem(
                    "location_list",
                    JSON.stringify(location_list)
                  );
                }

                navigate({
                  pathname: "/Home",
                  state: { loginToken: loginToken },
                });
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // localStorage.clear();

  return (
    <div className="bg-gradient-to-br from-purple-600 via-blue-300 to-purple-500 h-screen w-screen flex flex-wrap items-center justify-center relative overflow-hidden">
      <form
        className="flex items-center justify-center"
        onSubmit={handleSubmit}
      >
        <div className="items-center bg-gray-100 shadow-blue-500/50 shadow-lg w-60 p-6 rounded-lg  sm:h-1/2 md:h-1/2 lg:h-1/2 xl:h-full sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-2/3">
          <div className="flex items-center justify-center mb-4">
            {/* <img
              src="/images/saurmitralogo.png"
              alt="company logo"
              className="h-24"
            /> */}
          </div>
          <label className="text-gray-900 font-mono text-lg">Username</label>
          <input
            className="w-full py-2 bg-gray-100 text-gray-500 px-1 outline-none mb-5 rounded-lg shadow-blue-500/50 shadow-lg"
            type="text"
            placeholder="Enter your Username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          ></input>
          <label className="text-gray-900 font-mono text-lg">Password</label>
          <input
            className="w-full py-2 bg-gray-100 text-black px-1 outline-none mb-4 rounded-lg shadow-blue-500/50 shadow-lg"
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          ></input>
          {/* <Link to="/Container.js"> */}
          <button
            // onClick = {() => localStorage.setItem()}
            type="submit"
            className="bg-purple-600 shadow-blue-500/50 shadow-lg w-full text-white py-2 rounded-lg hover:bg-blue-600 transition-colors font-mono text-lg mb-3"
          >
            Login
          </button>
          {/* </Link> */}
        </div>
      </form>
    </div>
  );
};

export default Login;
